// ItemList.js
import React, { useState } from 'react';
import Item from './Item';
import SelectedItem from './Selected';

const ItemList = ({ items }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheck = (item, isChecked) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter(i => i.name !== item.name));
    }
  };

  return (
    <div className="item-list">
      {items.map((item, index) => <Item key={index} item={item} onCheck={handleCheck} />)}
      <div className="selected-items-container">
        {selectedItems.map((item, index) => <SelectedItem key={index} item={item} />)}
        {selectedItems.length > 0 && <button>Button Text</button>}
      </div>
    </div>
  );
};

export default ItemList;