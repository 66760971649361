import React from 'react';
import ItemList from './ItemList';

const itemsForSale = [
    { name: 'Instant Pot', image: 'img/instant_pot.jpg', price: '$100', description: 'This is a great item for your kitchen.', active: true },
    { name: 'Mirror Side Table', image: 'img/mirror_side_table.jpg', price: '$200', description: 'This is a great item for your kitchen.', active: true  },
    { name: 'Mirror Side Table', image: 'img/mirror_side_table.jpg', price: '$200', description: 'This is a great item for your kitchen.', active: true },
    { name: 'Mirror Side Table', image: 'img/mirror_side_table.jpg', price: '$200', description: 'This is a great item for your kitchen.', active: true },
    { name: 'Mirror Side Table', image: 'img/mirror_side_table.jpg', price: '$200', description: 'This is a great item for your kitchen.', active: true },
    { name: 'Mirror Side Table', image: 'img/mirror_side_table.jpg', price: '$200', description: 'This is a great item for your kitchen.', active: true },
    // Add more items here...
];

const ParentComponent = () => (
    <div>
        <h1>Items for Sale</h1>
        <ItemList items={itemsForSale} />
    </div>
);

export default ParentComponent;