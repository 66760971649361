// Selected.js
import React, { useState } from 'react';

const Selected = ({ item }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <span>{item.name}</span>
      <select value={selectedOption} onChange={handleChange}>
        <option value="option1">Buy</option>
        <option value="option2">Negotiate</option>
        <option value="option3">Question</option>
      </select>
      {selectedOption === 'option3' && <input type='text' value= "Ask question" />}
      {selectedOption === 'option2' && <input type='text' value= "price" />}
    </div>
  );
};

export default Selected;