// Item.js
import React, { useState } from 'react';

const Item = ({ item, onCheck }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
    onCheck(item, !checked);
  };

  return (
    <div className="item">
      <h2>{item.name}</h2>
      <img src={item.image} alt={item.name} />
      <p>{item.price}</p>
      <p>{item.description}</p>
      <input type="checkbox" id={`item-${item.name}`} checked={checked} onChange={handleChange} />
      <label htmlFor={`item-${item.name}`}>Select</label>
    </div>
  );
};
export default Item;